:global(#section-1) {
  & h4 {
    text-align: center;
    font-size: 16px;
    color: red;
    margin: 48px 0;
  }
  & h5 {
    text-align: center;
    font-size: 20px;
  }
  & h6 {
    color: red;
    margin: 36px 0 0px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    text-transform: initial;
  }
  & p {
    margin: 0px 0 12px;
    padding: 0;
    max-width: 100%;
    color: #000;
  }
  & hr {
    margin: 0;
  }
  & > div > div {
    max-width: 1110px;
    gap: 0;
  }
  & :global(#section-1-block-1) {
    & p {
      /* margin: 0; */
    }
  }
  & :global(#section-1-block-5),
  & :global(#section-1-block-6),
  & :global(#section-1-block-7),
  & :global(#section-1-block-9),
  & :global(#section-1-block-10) {
    & p {
      & strong {
        color: red;
        font-weight: 500;
      }
    }
  }
  & :global(#section-1-block-8) {
    & p {
      margin: 0;
      & strong {
        color: #000;
        font-weight: 600;
      }
    }
  }
}
:global(#landing-hero) {
  min-height: 76vh;
   &>div{
    &:nth-child(2){
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* background: #00000045; */
      &>div{
        /* padding-top: 16px; */
        @media (max-width: 767px) {
          padding: 0 32px 44px;
          margin-left: 0;
        }
        & h2{
          @media (max-width: 767px) {
            
          }
        }
        & h1, & p{
          color: #fff;
          text-align: center;
          font-size: 50px;
          font-weight: 700;
          margin: 0;
          line-height: 100%;
          @media (max-width: 767px) {
            font-size: 36px;
            text-align: left;
          }
        }
      }
      & header{
        /* background: #ffffffcf; */
        padding: 32px;
        border-radius: 2px;
      }
    }
   }
}
:global(#second-landing-section) {
  & p{
    word-break: break-word;
  }
}