.privacyWrapper {
  max-width: 1110px;
  padding: 64px 32px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 767px) {
    padding: 32px 20px;
  }
  & .tableWrapper {
    max-width: calc(100vw - 40px);
    overflow-x: auto;
    margin-bottom: 32px;
    & table {
      min-width: 990px;
    }
  }
  & a {
    text-decoration: underline;
  }
  & h4 {
    text-align: center;
    font-size: 16px;
    color: red;
    margin: 48px 0;
  }
  & h5 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }
  & h6 {
    color: red;
    margin: 36px 0 0px;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
  }
  & p {
    margin: 0px 0 12px;
    padding: 0;
    max-width: 100%;
    color: #000;
  }
  & hr {
    margin: 0;
  }
  & ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    & li {
      color: #000;
    }
  }
  & .partiesTable {
    & td {
      border-bottom: none;
      width: 50% !important;
    }
  }
  .innerTable {
    margin-bottom: 0;
    min-width: auto !important;
    & tr {
      padding-bottom: 16px;
      &:nth-child(1) {
        border-bottom: 1px solid #bdd7ee;
      }
    }
  }
  .threeColumnTable {
    & td {
      &:nth-child(1) {
        width: 33%;
      }
      &:nth-child(2) {
        width: 33%;
      }
      &:nth-child(3) {
        width: 33%;
      }
    }
  }
  & .sharingTable {
    & td,
    & th {
      width: auto !important;
    }
  }
  .erasureList {
    list-style: auto;
  }
  & table {
    width: 100%;
    text-align: left;
    border: 1px solid #bdd7ee;
    & ul {
      & li {
        padding: 0;
        color: #000;
      }
    }
    & th,
    & td {
      height: 26px;
      padding: 0 12px;
      border-left: 1px solid #bdd7ee;
      border-bottom: 1px solid #bdd7ee;
      vertical-align: baseline;
      color: #000;
    }
    & td,
    & th {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 70%;
      }
    }
    & tr {
      padding-bottom: 16px;
      &:nth-child(1) {
        border-bottom: 3px solid #8eaadb;
      }
    }
  }
}
.mb_0 {
  margin-bottom: 0px !important;
}
:global(#privacy-section-1) {
  & > div > div {
    max-width: 1110px;
    gap: 0;
  }
  & :global(#section-1-block-1) {
    & p {
      /* margin: 0; */
    }
  }
  & :global(#section-1-block-5),
  & :global(#section-1-block-6),
  & :global(#section-1-block-7),
  & :global(#section-1-block-9),
  & :global(#section-1-block-10) {
    & p {
      & strong {
        color: red;
        font-weight: 500;
      }
    }
  }
  & :global(#section-1-block-8) {
    & p {
      margin: 0;
      & strong {
        color: #000;
        font-weight: 600;
      }
    }
  }
}
